import LayoutA5 from "../../components/layout-a5"
import React from "react"
import  Login from '../../components/login'

export default class A5Login extends React.Component {
  render() {
    const color=this.props.pageContext.element.color
    const src="/demos/themeA5/profile/enrollInfoList"
    return(
      <LayoutA5 >
        <Login color={color} src={src} />
      </LayoutA5>
    )
  }
}